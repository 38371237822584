import type { ActivatedRouteSnapshot, Routes } from '@angular/router';
import {
    DistributionFeature,
    FootnotesFeature,
    ImprintPageFeature,
    ngrxLoaderGuard,
    VehicleFeature,
} from '@hyundai/ng-common-lib';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { distributionPageTitleResolver, vdpTitleResolver } from '../../utils/title-resolver';

export const vehicleProvider = [provideState(VehicleFeature.vehicleState), provideEffects(VehicleFeature.effects)];
export const distributionProvider = [
    provideState(DistributionFeature.distributionState),
    provideEffects(DistributionFeature.effects),
];
export const footnotesProvider = [provideState(ImprintPageFeature.state), provideEffects(ImprintPageFeature.effects)];
export const imprintProvider = [provideState(FootnotesFeature.state), provideEffects(FootnotesFeature.effects)];

export default [
    {
        path: '',
        loadComponent: () => import('./list-page.component'),
        canActivate: [
            ngrxLoaderGuard({
                waitUntilLoadedSelector: VehicleFeature.vehicleState.selectIsLoadingList,
                dataToCheckSelector: VehicleFeature.vehicleState.selectList,
                actionToDispatch: (route) => {
                    const filterQueryParam = route.queryParams['filter'] ?? undefined;

                    if (filterQueryParam !== undefined) {
                        try {
                            return VehicleFeature.vehicleActions.init({ initalFilter: JSON.parse(filterQueryParam) });
                        } catch {
                            return VehicleFeature.vehicleActions.init({});
                        }
                    }

                    return VehicleFeature.vehicleActions.init({});
                },
            }),
            ngrxLoaderGuard({
                waitUntilLoadedSelector: VehicleFeature.vehicleState.selectIsLoadingNumberOfVehicles,
                dataToCheckSelector: VehicleFeature.vehicleState.selectNumberOfVehicles,
            }),
        ],
        title: (route) => {
            const filterQueryParam = route.queryParams['filter'] ?? undefined;
            return filterQueryParam === undefined
                ? 'Hyundai Fahrzeugbestand'
                : 'Hyundai Fahrzeugbestand Suchergebnisse';
        },
        pathMatch: 'full',
        providers: [vehicleProvider],
        runGuardsAndResolvers: 'pathParamsOrQueryParamsChange',
    },
    {
        path: ':slug',
        loadComponent: () => import('./detail-page.component'),
        providers: [vehicleProvider, imprintProvider, footnotesProvider],
        canActivate: [
            ngrxLoaderGuard({
                waitUntilLoadedSelector: VehicleFeature.vehicleState.selectIsLoadingDetail,
                dataToCheckSelector: VehicleFeature.vehicleState.selectDetail,
                actionToDispatch: (route) =>
                    VehicleFeature.vehicleActions.loadVehicleDetail({ slug: route.params['slug'] }),
            }),
        ],
        title: vdpTitleResolver('Hyundai'),
        pathMatch: 'full',
        runGuardsAndResolvers: 'pathParamsChange',
    },
    {
        path: ':slug/haendleranfrage',
        loadComponent: () => import('../checkouts/distribution-page/distribution-page.component'),
        providers: [vehicleProvider, distributionProvider],
        canActivate: [
            ngrxLoaderGuard({
                waitUntilLoadedSelector: VehicleFeature.vehicleState.selectIsLoadingDetail,
                dataToCheckSelector: VehicleFeature.vehicleState.selectDetail,
                actionToDispatch: (route) =>
                    VehicleFeature.vehicleActions.loadVehicleDetail({ slug: route.params['slug'] }),
            }),
        ],
        title: (route: ActivatedRouteSnapshot) => distributionPageTitleResolver(route)(),
        pathMatch: 'full',
    },
] satisfies Routes;
